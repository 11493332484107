import React from 'react'
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import theme from './src/templates/theme.js'


export default function MuiRootWrapper({ element }) {
  // const userLang = navigator.language || navigator.userLanguage;
  // console.log(userLang)
  // const [language, setLanguage] = React.useState(false);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        {/* <Layout> */}
          {element}

        {/* </Layout> */}
      </ThemeProvider>
    </StyledEngineProvider>
  );
}
