import { createTheme } from '@mui/material/styles';

const theme = createTheme({
    palette: {
      mode: 'dark',
      primary: {
          main: "#131313",
          accent: "#1986EC", // Акцентный цвет
          white: 'white',
          grey: "#A0A0A0"
      },
      background: {
        main: "#131313", // Цвет основного фона
        bigCard: "#000", // Цвет фона большой карточки
        card: "#1D1D1D", // Цвет фона карточки
        tag: "#000", // Цвет тэга
      },
      width: {
        limit: "1280px",
        text: "600px",
        image: "928px",
        mobile: '787px'
      },
    },
  typography: {
    fontFamily: ["Raleway"].join(","),
    h1: {
      // Название товара
      color: "#fff",
      fontSize: 20,
      "@media (max-width: 1024px)": {},
      "@media (max-width: 767px)": {},
    },
    h2: {
      // Основные большие заголовки
      color: "#fff",
      fontSize: 50,
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: "130%",
      "@media (max-width: 1024px)": {
        fontSize: "6vw",
      },
      "@media (max-width: 767px)": {
        fontSize: "6.76vw",

      },
    },
    h3: {
      // Заголовки структурных пунктов и их номеров
      color: "#fff",
      fontSize: 20,
      "@media (max-width: 1024px)": {
        fontSize: "1.6vw",
      },
      "@media (max-width: 767px)": {
        fontSize: "3vw",
      },
    },
    h4: {
      // Пункты с номерами
      color: "#fff",
      fontSize: 18,
      "@media (max-width: 1024px)": {},
      "@media (max-width: 767px)": {},
    },
    h5: {
      // Пункты без номеров в обводке
      color: "#fff",
      fontSize: 14,
      "@media (max-width: 1024px)": {},
      "@media (max-width: 767px)": {},
    },
    h6: {
      // Заголовки пунктов на карточке товара
      color: "#fff",
      fontSize: 40,
      "@media (max-width: 1024px)": {
        marginBottom: 100,
      },
      "@media (max-width: 767px)": {
        marginBottom: 20,
      },
    },
    body1: {
      // Обычный текст
      color: "#fff",
      fontSize: 14,
      lineHeight: "150%",
      "@media (max-width: 1024px)": {
        fontSize: "2vw",
        lineHeight: "180%",
      },
      "@media (max-width: 767px)": {
        fontSize: "4vw",
      },
    },
    body2: {
      // Серый обычный текст
      color: "#A0A0A0",
      fontSize: 17,
      lineHeight: "150%",
      fontWeight: 400,
      "@media (max-width: 1024px)": {
        fontSize: 17,
        lineHeight: "150%",
      },
      "@media (max-width: 767px)": {
        fontSize: "3.5vw",
      },
    },
    subtitle1: {
      // Текст в кнопках
      color: "#000",
      fontSize: 14,
      textTransform: "none",
      lineHeight: "1 !important",
      "@media (max-width: 1024px)": {
        fontSize: "1.4vw",
      },
      "@media (max-width: 767px)": {
        fontSize: "2.8vw",
      },
    },
    subtitle2: {
      // Текст ссылок в меню в шапке
      color: "#fff",
      fontSize: 12,
    },
    lightGray: {
        color: "#A0A0A0",
        fontSize: 12,
        lineHeight: "150%",
        "@media (max-width: 1024px)": {
          fontSize: "1.2vw",
          lineHeight: "180%",
        },
        "@media (max-width: 767px)": {
          fontSize: "3.5vw",
        },
    }
  },
  width: {
    limit: "1280px",
  },
  overrides: {
    // Название класса компонента
    MuiButton: {
      // Название css-правила, смотреть в mui
      text: {},
    },
  },
  props: {
    // Название класса компонента
    MuiButton: {
      // Props, который нужно изменить
      disableRipple: true, // Отменяет эффект волны для всех кнопок
    },
    MuiModal: {
      disableScrollLock: true,
    },
  },
})


export default theme
