exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-page-js": () => import("./../../../src/pages/page.js" /* webpackChunkName: "component---src-pages-page-js" */),
  "component---src-pages-send-data-js": () => import("./../../../src/pages/send-data.js" /* webpackChunkName: "component---src-pages-send-data-js" */),
  "component---src-templates-contact-page-jsx": () => import("./../../../src/templates/contactPage.jsx" /* webpackChunkName: "component---src-templates-contact-page-jsx" */),
  "component---src-templates-main-page-jsx": () => import("./../../../src/templates/mainPage.jsx" /* webpackChunkName: "component---src-templates-main-page-jsx" */),
  "component---src-templates-project-jsx": () => import("./../../../src/templates/project.jsx" /* webpackChunkName: "component---src-templates-project-jsx" */),
  "component---src-templates-project-page-jsx": () => import("./../../../src/templates/projectPage.jsx" /* webpackChunkName: "component---src-templates-project-page-jsx" */),
  "component---src-templates-staff-page-jsx": () => import("./../../../src/templates/staffPage.jsx" /* webpackChunkName: "component---src-templates-staff-page-jsx" */)
}

